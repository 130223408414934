






























































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapMutations, MutationMethod } from 'vuex';

import Error from '@/components/fmh_components/common/Error/Error.vue';
import Loader from '@/components/fmh_components/common/Loader/Loader.vue';
import BankingProfileTable from '@/components/BankingProfileTable/BankingProfileTable.vue';
import ChildHeader from '@/components/fmh_components/common/ChildHeader/ChildHeader.vue';
import NoteTagsHint from '@/components/fmh_components/common/NoteTagsHint/NoteTagsHint.vue';
import DateSelection from '@/components/fmh_components/common/DateSelection/DateSelection.vue';
import FilterBankingProfile from '@/components/FilterBankingProfile/FilterBankingProfile.vue';

import {
  ADDITIONAL_HEADERS_BANKING_PROFILE,
  BASE_BLACK_COLOR,
  DEFAULT_HEADERS_BANKING_PROFILE,
  DEFAULT_SELECTED_HEADERS_BANKING_PROFILE,
  MODEL_FIELDS,
  WORLD_COUNTRIES,
} from '@/utils/constants';

import { timePeriodOptions } from '@/utils/helpers';

import { FilterParamsData } from '@/interfaces';

interface ReportData {
  slug: String;
}

@Component({
  components: {
    Error,
    Loader,
    BankingProfileTable,
    ChildHeader,
    NoteTagsHint,
    DateSelection,
    FilterBankingProfile,
  },
  computed: {
    ...mapGetters({
      isAuthorized: 'auth/isAuthorized',
      allReports: 'bankingProfile/getReports',
      singleReport: 'bankingProfile/getSingleReport',
      error: 'bankingProfile/error',
      dates: 'bankingProfile/getAvailableDates',
      isLoadingByDate: 'bankingProfile/getLoadingByDate',
      loading: 'bankingProfile/loading',
      user: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      updateExistingReport: 'bankingProfile/updateExistingReport',
      fetchSingleReport: 'bankingProfile/fetchSingleReport',
      fetchAvailableDates: 'bankingProfile/fetchAvailableDates',
      fetchReportByDate: 'bankingProfile/fetchReportByDate',
    }),
    ...mapMutations({
      updateReport: 'bankingProfile/updateReport',
      setSingleReport: 'bankingProfile/setSingleReport',
    }),
  },
})
export default class BPReportOverview extends Vue {
  [x: string]: any;
  isAuthorized!: Boolean;
  allReports!: Array<ReportData>;
  singleReport: any;
  color = BASE_BLACK_COLOR;
  modelFields: FilterParamsData = {
    ...MODEL_FIELDS,
    zeitraum: timePeriodOptions().defaultSelected,
  };
  headers: object[] = [...DEFAULT_HEADERS_BANKING_PROFILE].concat(
    DEFAULT_SELECTED_HEADERS_BANKING_PROFILE
  );
  chipsHeaders = ADDITIONAL_HEADERS_BANKING_PROFILE;
  originalHeaders = [...DEFAULT_HEADERS_BANKING_PROFILE];
  rows: Array<Object> = [];
  stringOptions = {};
  selectedHeaders: Array<String> = [];
  menuItems: any[] = [];
  isShowEditNote = false;
  isEditNote = false;
  isShowNewNote = false;
  isCreatedNewNote = false;
  isEdit = false;
  currentNote = '';
  newNote = '';
  currentHeadline = '';
  isShowEditTitle = false;
  titleRules: any[] = [];
  isUpdatingFilters = false;
  isShowResetButton = false;
  dates!: Array<{ date: String; slug: String } | undefined>;
  contentWidth = {
    width: '100%',
  };
  isFiltered = false;

  updateReport!: MutationMethod;
  setSingleReport!: MutationMethod;
  updateMenuItemsModel!: Function;
  setMenuItems!: Function;
  fetchSingleReport!: (payload) => Promise<Object>;
  updateExistingReport!: (payload) => Promise<Object>;
  fetchAvailableDates!: (payload) => Promise<Object>;
  fetchReportByDate!: (payload) => Promise<Object>;

  @Watch('singleReport', { immediate: true }) async setValues() {
    if (this.singleReport.rows.length) {
      (this.$options as any).dateVom = this.$date(
        this.singleReport.timestamp
      ).format('DD.MM.YYYY');
      this.menuItems = this.setMenuItems(this.singleReport, 'bpReport');
      this.setState(this.singleReport);
      this.rows = this.singleReport.rows;
      this.validateResetButton();
      this.setTableWidth(false);
    }
  }

  @Watch('isAuthorized') async fetchReports(val: boolean) {
    if (val) {
      const { rows, ...rest } = this.singleReport;
      if (this.allReports.length === 0 && !Object.keys(rest).length) {
        await this.fetchSingleReport(this.$router.currentRoute.params.id);
      }
      if (this.dates.length === 0) {
        await this.fetchAvailableDates(this.$date);
      }
    }
  }

  get validateUser() {
    return this.user?.isDemoUser || false;
  }
  get info() {
    return 'In der Demoversion werden nur 10 Banken von 257 angezeigt.';
  }
  get rowsItems() {
    return this.rows;
  }
  get contentMinHeight() {
    const height = window.innerHeight - 290;
    return {
      'min-height': `${height}px`,
    };
  }
  get width() {
    return this.contentWidth;
  }

  get availableDates() {
    const arr = (this.dates as any).map((item) => {
      return new Date(item.date).getTime();
    });
    const search = this.$date(Math.max(...arr)).format('YYYY-MM-DD');
    return (this.dates as any).filter((item) => item.date !== search);
  }
  get content() {
    return this.singleReport;
  }
  handleChangeAllCountriesSelected(val) {
    //stringOptions always is
    const { config } = this.singleReport;
    if (val) {
      this.modelFields = {
        ...this.modelFields,
        standort: config.stringOptions?.standortlaender || [],
        region: [
          'dach',
          'europa',
          'eu',
          'benelux',
          'brics',
          'afrika',
          'asien',
          'australien und ozeanien',
          'amerikas',
        ],
      };
    } else {
      this.modelFields = {
        ...this.modelFields,
        standort: [],
        region: [],
      };
    }
  }
  handleChangeRegion(val) {
    const allCurrentCountries: string[] = [];
    val.forEach((item) => {
      for (let i = 0; i < WORLD_COUNTRIES[item].length; i += 1) {
        allCurrentCountries.push(WORLD_COUNTRIES[item][i]);
      }
    });
    Object.assign(this.modelFields, {
      region: val,
      standort: allCurrentCountries,
    });
  }
  handleChangeKeyAreas(val) {
    this.modelFields = { ...this.modelFields, schwerpunkte: val };
  }
  handleChangeLocationCountries(values) {
    if (!values.length) {
      this.modelFields = { ...this.modelFields, region: [], standort: [] };
    } else {
      const current = {};
      const region: string[] = [];
      this.modelFields.region.forEach((key) => {
        const arr: string[] = [];
        WORLD_COUNTRIES[key].forEach((item) => {
          if (!arr.includes(item) && values.includes(item)) {
            arr.push(item);
          }
          Object.assign(current, { [key]: arr });
        });
      });
      Object.keys(current).forEach((key) => {
        if (current[key].length === WORLD_COUNTRIES[key].length) {
          region.push(key);
        }
      });
      this.modelFields = { ...this.modelFields, standort: values, region };
    }
  }
  handleChangePeriod(val) {
    this.modelFields = { ...this.modelFields, zeitraum: val };
  }
  handleChangeStockExchangeListed(val) {
    this.modelFields = { ...this.modelFields, boersennotiert: val };
  }
  onSelectedHeaders(val) {
    this.selectedHeaders = val;
  }
  onHeadersChanged(val) {
    this.headers = val;
    this.setTableWidth(false);
  }
  onRowsChanged(val) {
    this.isFiltered = false;
    this.rows = val;
    this.validateResetButton();
  }
  onLoadingChanged(val) {
    this.contentWidth = {
      width: '100%',
    };
    if (val) {
      this.rows = [];
    }
    this.isFiltered = val;
  }
  toggleAll(val) {
    const result: string[] = [];
    if (val) {
      for (let i = 0; i < this.chipsHeaders.length; i += 1) {
        result.push(this.chipsHeaders[i].value);
      }
      this.selectedHeaders = result;
    } else {
      this.selectedHeaders = [];
    }
  }
  setTableWidth(reset: boolean) {
    setTimeout(() => {
      const windowWidth = window.innerWidth - 410;
      const contentWidth = (this.$refs as any)?.table?.$el?.firstChild
        ?.children?.[0]?.scrollWidth;
      if (reset) {
        this.contentWidth = {
          width: `${windowWidth}px`,
        };
      }
      if (contentWidth > windowWidth && !reset && this.selectedHeaders.length) {
        this.contentWidth = {
          width: `${contentWidth}px`,
        };
      } else {
        this.contentWidth = {
          width: '100%',
        };
      }
    }, 200);
  }
  async getLatestDataOfTheReport() {
    const arr = (this.dates as any).map((item) => {
      return new Date(item.date).getTime();
    });
    const max = this.$date(Math.max(...arr)).format('YYYY-MM-DD');
    const search = (this.dates as any).find((item) => item.date === max);

    (this.$options as any).dateVom = this.$date(max).format('DD.MM.YYYY');
    await this.fetchReportByDate({
      slug: search?.slug,
      note: (this.content as any).note,
      title: (this.content as any).title,
      config: (this.content as any).config,
    });
  }
  async handleChangeDate(val) {
    (this.$options as any).dateVom = this.$date(val).format('DD.MM.YYYY');
    if (val) {
      const search: { slug: String; date: String } | undefined =
        this.dates.find((item) => item?.date === val);
      if (search) {
        await this.fetchReportByDate({
          slug: search?.slug,
          note: (this.content as any).note,
          title: (this.content as any).title,
          config: (this.content as any).config,
        });
      }
    } else {
      await this.fetchSingleReport(this.$router.currentRoute.params.id);
    }
  }
  validateResetButton() {
    const arr: string[] = [];
    Object.keys(this.modelFields).forEach((key) => {
      if (key === 'zeitraum' && this.modelFields[key].length !== 1) {
        arr.push(key);
      }
      if (
        (key !== 'zeitraum' && this.modelFields?.[key]?.length) ||
        (typeof this.modelFields?.[key] === 'boolean' &&
          this.modelFields?.[key])
      ) {
        arr.push(key);
      }
    });
    this.isShowResetButton = !!arr.length;
  }
  async updateFilters() {
    this.isUpdatingFilters = true;
    const config = {
      stringOptions: this.singleReport.config?.stringOptions,
      selectedHeaders: this.selectedHeaders,
      modelFields: this.modelFields,
      total: this.rows.length,
    };
    await this.updateExistingReport({
      config,
      field: 'config',
      slug: this.$router.currentRoute.params.id,
    });
    this.updateReport({
      config,
      slug: this.$router.currentRoute.params.id,
      title: (this.content as any).title,
      rows: this.rows,
      note: (this.content as any).note || '',
      timestamp: this.$date().format('YYYY-MM-DDThh:mm'),
    });
    this.isUpdatingFilters = false;
  }
  updateHeadline() {
    this.titleRules = [(v) => !!v || 'Titel ist erforderlich'];
    setTimeout(async () => {
      if ((this.$refs.form as any).validate()) {
        this.isEdit = true;
        try {
          const payload = {
            slug: this.$router.currentRoute.params.id,
            title: this.currentHeadline,
            rows: this.rows,
            note: (this.content as any).note || '',
            timestamp: (this.content as any).timestamp,
            config: (this.content as any).config,
            field: 'title',
          };
          await this.updateExistingReport(payload);
          this.updateReport(payload);
          this.currentHeadline = '';
          this.titleRules = [];
          this.isShowEditTitle = false;
          this.isEdit = false;
        } catch (e) {
          this.currentHeadline = '';
          this.titleRules = [];
          this.isShowEditTitle = false;
          this.isEdit = false;
        }
      }
    });
  }
  updateNote() {
    setTimeout(async () => {
      if (this.isShowEditNote) {
        this.isEditNote = true;
        try {
          const payload = {
            slug: this.$router.currentRoute.params.id,
            config: (this.content as any).config,
            rows: this.rows,
            title: (this.content as any).title,
            timestamp: (this.content as any).timestamp,
            field: 'note',
            note: this.currentNote || null,
          };
          await this.updateExistingReport(payload);
          this.updateReport(payload);
          this.currentNote = '';
          this.isShowEditNote = false;
          this.isEditNote = false;
        } catch (e) {
          this.currentNote = '';
          this.isShowEditNote = false;
          this.isEditNote = false;
        }
      }
    }, 100);
  }
  createNote() {
    setTimeout(async () => {
      if (this.isShowNewNote) {
        this.isCreatedNewNote = true;
        try {
          const payload = {
            slug: this.$router.currentRoute.params.id,
            config: (this.content as any).config,
            rows: this.rows,
            title: (this.content as any).title,
            timestamp: (this.content as any).timestamp,
            field: 'note',
            note: this.newNote || null,
          };
          await this.updateExistingReport(payload);
          this.updateReport(payload);
          this.newNote = '';
          this.isShowNewNote = false;
          this.isCreatedNewNote = false;
        } catch (e) {
          this.newNote = '';
          this.isShowNewNote = false;
          this.isCreatedNewNote = false;
        }
      }
    }, 100);
  }
  onEditTitle() {
    this.currentHeadline = (this.content as any).title;
    this.isShowEditTitle = true;
  }
  onEditNote() {
    this.currentNote = (this.content as any).note;
    this.isShowEditNote = true;
  }
  changeVisibilityNewNote() {
    this.isShowNewNote = !this.isShowNewNote;
  }
  changeCurrentNote(val) {
    this.currentNote = val;
  }
  openMenu(title) {
    this.menuItems = this.updateMenuItemsModel(title, this.menuItems, true);
  }
  closeMenu(title) {
    localStorage.setItem('hint', 'false');
    if (this.isShowEditNote) {
      this.currentNote = '';
      this.isShowEditNote = false;
    }
    if (this.isShowNewNote) {
      this.isShowNewNote = false;
      this.newNote = '';
    }
    this.menuItems = this.updateMenuItemsModel(title, this.menuItems);
  }
  setState(report) {
    const { config } = report;
    const { stringOptions, selectedHeaders, modelFields } = config;
    this.selectedHeaders = selectedHeaders;
    this.modelFields = modelFields;
    this.stringOptions = stringOptions;
    if (selectedHeaders.length) {
      const arr: Object[] = [];
      selectedHeaders.forEach((header) => {
        const search = this.chipsHeaders.find(
          (item) => (item as any).value === header
        );
        if (search) {
          arr.push(search);
        }
      });
      if (arr.length) {
        const result: Array<Object> = [];
        this.originalHeaders.forEach((header) => {
          result.push(header);
        });
        arr.forEach((header) => {
          result.push(header);
        });
        this.headers = [...new Set(result)];
      } else {
        this.headers = this.originalHeaders;
      }
    }
  }
  resetFilters() {
    this.modelFields = {
      ...MODEL_FIELDS,
      region: ['dach'],
      standort: WORLD_COUNTRIES['dach'],
      zeitraum: timePeriodOptions().defaultSelected,
    };
    this.headers = [...DEFAULT_HEADERS_BANKING_PROFILE].concat(
      DEFAULT_SELECTED_HEADERS_BANKING_PROFILE
    );
    this.selectedHeaders = [
      'gesamtkapitalquote',
      'eigenkapitalrendite',
      'schwerpunkte',
      'bankratings',
    ];
    this.rows = this.content.rows.map((item) => {
      if (
        item.hasOwnProperty('gesamtkapitalquote') ||
        item.hasOwnProperty('eigenkapitalrendite')
      ) {
        return {
          ...item,
          gesamtkapitalquote:
            item?.gesamtkapitalquote?.filter(
              (i) => Number(i.jahr) === Number(this.modelFields.zeitraum[0])
            ) || [],
          eigenkapitalrendite:
            item?.eigenkapitalrendite?.filter(
              (i) => Number(i.jahr) === Number(this.modelFields.zeitraum[0])
            ) || [],
        };
      }
      return item;
    });
    this.validateResetButton();
    this.setTableWidth(true);
  }

  handleChangeNewNote(val) {
    this.newNote = val;
  }

  async created() {
    const { rows, ...rest } = this.singleReport;
    if (this.isAuthorized) {
      (this.$options as any).dateVom = '';
      if (this.allReports.length !== 0) {
        const existingReport: any = this.allReports.find(
          (item) => item.slug === this.$router.currentRoute.params.id
        );
        const { rows, ...rest } = existingReport;
        const rowsItems = JSON.parse(localStorage.getItem('rows') || '[]');
        this.isFiltered = true;
        setTimeout(() => {
          this.setSingleReport({ rows: rowsItems, ...rest });
        }, 100);
      }
      if (this.allReports.length === 0 && !Object.keys(rest).length) {
        await this.fetchSingleReport(this.$router.currentRoute.params.id);
      }
      /* if (this.dates.length === 0) {
        this.loading = true;
        await this.fetchAvailableDates(this.$date);
        this.loading = false;
      }*/
    }
  }
}
